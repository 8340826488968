@import "variables";

div.error {
	padding-top: 10%;
	text-align: center;

	span.error {
		color: $baseColor1;
		font-size: 5.5em;

		span {
			&#light {
				color: #aaa; }

			&#dark {
				color: $baseColor2; } } } }
